<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Paso 2 de 4
            </template>
            <template v-slot:subtitle>
                <div class="p-fluid p-formgrid p-grid" v-if="!nuevo || formData.exoneracion || formData.serv_comunit">
                    <div class="p-field p-col-12 p-md-4">
						<label>Asignatura: {{asignaturas.length}}</label>
						<Dropdown v-model="asignatura" :options="asignaturas" optionLabel="nombre" placeholder="Seleccione..." @change="Validacion1()"/>
					</div>
                    <div class="p-field p-col-12 p-md-3">
						<label>Sección: {{secciones.length}}</label>
						<Dropdown v-model="seccion" :options="secciones" optionLabel="nombre" placeholder="Seleccione..."/>
					</div>
                    <div class="p-field p-col-12 p-md-2">
						<label> </label>
						<Button label="Agregar" icon="pi pi-plus-circle" class="p-button-info p-mr-2 p-mb-2" @click="Agregar" />
					</div>
                    <div class="p-field p-col-12 p-md-3">
						Exceso de Unidades de Crédito <Checkbox v-model="excesouc" :binary="true"/>
					</div>
                </div>          
            </template>
            <template v-slot:content>  
                <div class="p-fluid p-formgrid p-grid">
					<div class="p-field p-col-12 p-md-12">
                        <DataTable :value="inscripcion" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" class="p-datatable-sm">
                            <template #header>
                                <div class="table-header">
                                    <h5 class="p-m-0">Asignaturas: {{inscripcion.length}} - Total de Unidades de Crédito: {{tuc}}</h5>
                                </div>
                            </template>
                            <Column header="Nueva" :sortable="true">
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.ver">
                                        NO
                                    </div>
                                    <div v-else>
                                        SI
                                    </div>
                                </template>
                            </Column>
                            <Column header="Período" :sortable="true">
                                <template #body="slotProps">
                                    {{slotProps.data.periodo}}
                                </template>
                            </Column>
                            <Column header="Código" :sortable="true">
                                <template #body="slotProps">
                                    {{slotProps.data.cod}}
                                </template>
                            </Column>
                            <Column header="Asignatura" :sortable="true">
                                <template #body="slotProps">
                                    {{slotProps.data.asignatura}}
                                </template>
                            </Column>
                            <Column header="UC" :sortable="true">
                                <template #body="slotProps">
                                    {{slotProps.data.uc}}
                                </template>
                            </Column>
                            <Column header="Semestre" :sortable="true">
                                <template #body="slotProps">
                                    {{slotProps.data.sem}}
                                </template>
                            </Column>
                            <Column header="Sección" :sortable="true">
                                <template #body="slotProps">
                                    {{slotProps.data.secc}}
                                </template>
                            </Column>
                            <Column header="Disponible" :sortable="true">
                                <template #body="slotProps">
                                    {{slotProps.data.disp}}
                                </template>
                            </Column>
                            <Column header="Opciones">
                                <template #body="slotProps">
                                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="Eliminar(slotProps.data)"/>
                                </template>
                            </Column>
                        </DataTable>
                        <small v-show="validationErrors.asignatura && submitted" style="color: red;">Debe seleccionar al menos una Asignatura a Inscribir</small>
					</div>
                </div>
            </template>
            <template v-slot:footer>
                <h6 v-if="formData.exoneracion && facturaprev">
                    <strong align="justify">
                        Factura asociada: {{facturaprev.factura}}                  
                    </strong>
                </h6>
                <h6 v-if="formData.exoneracion && facturaprev">
					<strong v-if="facturaprev.formapago==1">Al Contado</strong>
					<strong v-if="facturaprev.formapago==2">A Credito</strong>
					<strong v-if="facturaprev.formapago==3">Al Contado Una Materia</strong>
                </h6>
                <h5 v-if="!verificacion">
                        <strong align="justify">
                            Para este caso, no se puede aplicar la opción de exoneración, 
                            debe usar la opción de eliminar proceso de inscripción.                      
                        </strong>
                </h5>
                <div class="p-grid p-nogutter p-justify-between">
                    <Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button label="Siguiente" @click="Horario()" icon="pi pi-angle-right" iconPos="right" v-if="verificacion"/>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import API from "@/service/API";
const Consulta = new API('Inscripcion','Procesar');

export default {
	props: {
        formData: Object
    },
    data () {
        return {
            submitted: false,
            validationErrors: {},
            asignaturas: [],
            asignatura: null,
            secciones: [],
            seccion: null,
            inscripcion: [],
            horario: [],
            cruce: [],
            tuc: 0,
            nuevo: false,
            verificacion: true,
            previa: 0,
            facturaprev: null,
            excesouc: false,
        }
    },
	created() {
		this.Mostrar();
	},
    methods: {
		Mostrar() {
            this.asignaturas = [];
            this.facturaprev = null;
            this.nuevo = false;
			Consulta.Procesar('Asignaturas',{
				id: this.$store.state.matricula,
				inscripcion: this.inscripcion,
                periodo: this.formData.periodo,
			}).then(response => {
                //this.$store.state.error = response.result.asignaturas;
				//this.$store.state.error = response.result.facturaprev;
                if(response.result.facturaprev){
                    this.facturaprev = response.result.facturaprev[0];
                }
				if(this.inscripcion.length==0){
                    this.inscripcion = response.result.inscripcion;
                }
                this.nuevo = response.result.nuevo;
                if (this.nuevo){
                    this.inscripcion = response.result.asignaturas;
                } else if(this.formData.exoneracion && this.nuevo && response.result.inscripcion.length>0){
                    this.asignaturas = response.result.asignaturas;
                    this.inscripcion = response.result.inscripcion;
                } else if(this.formData.serv_comunit){
                    this.asignaturas = response.result.asignaturas.filter(val => val.tipo == 7);
                } else {
                    this.asignaturas = response.result.asignaturas;
                }
                this.tuc = 0;
                this.inscripcion.forEach(element => {
                    this.tuc = this.tuc + element.uc;
				});
                this.Verificacion();
				this.$store.commit('Loading');
			});
		},
		Validacion1() {
            this.seccion=null;
			Consulta.Procesar('Secciones',{
				id: this.$store.state.matricula,
				asignatura: this.asignatura.code,
				inscripcion: this.inscripcion,
                periodo: this.formData.periodo,
			}).then(response => {
				//this.$store.state.error = response.result;
				this.secciones = response.result;
                this.$store.commit('Loading');
			});
		},
        Verificacion(){
            if(this.formData.exoneracion){
                var contador;
                contador = 0
				this.inscripcion.forEach(element => {
					if(element.ver){
						contador = contador + 1;
					}
				});
                if (contador>0){this.verificacion=true;} else {this.verificacion=false;}
                this.previa = contador;

                if(this.facturaprev==null){this.verificacion=false;}
            }
        },
		Agregar() {
            if(this.formData.exoneracion || this.formData.serv_comunit){
                if((this.seccion.uc+this.tuc)<=21 || this.excesouc){
                    this.inscripcion.push(this.seccion);
                    this.tuc = this.tuc + this.seccion.uc;
                    this.asignatura = null;
                    this.seccion = null;
                    //this.Mostrar();
                }
            } else {
                if(((this.seccion.uc+this.tuc)<=21 && this.formData.formapago.code<3)  || this.excesouc){
                    this.inscripcion.push(this.seccion);
                    this.tuc = this.tuc + this.seccion.uc;
                    this.asignatura = null;
                    this.seccion = null;
                    this.Mostrar();
                }
                if(this.inscripcion.length==0 && this.formData.formapago.code==3){
                    this.inscripcion.push(this.seccion);
                    this.tuc = this.tuc + this.seccion.uc;
                    this.asignatura = null;
                    this.seccion = null;
                    this.Mostrar();
                }
                if(this.inscripcion.length==0 && this.formData.formapago.code==4){
                    this.inscripcion.push(this.seccion);
                    this.tuc = this.tuc + this.seccion.uc;
                    this.asignatura = null;
                    this.seccion = null;
                    this.Mostrar();
                }
                if(this.inscripcion.length<=1 && this.formData.formapago.code==5){
                    this.inscripcion.push(this.seccion);
                    this.tuc = this.tuc + this.seccion.uc;
                    this.asignatura = null;
                    this.seccion = null;
                    this.Mostrar();
                }
                if(((this.seccion.uc+this.tuc)<=21 && this.formData.formapago.code==6) || this.excesouc){
                    this.inscripcion.push(this.seccion);
                    this.tuc = this.tuc + this.seccion.uc;
                    this.asignatura = null;
                    this.seccion = null;
                    this.Mostrar();
                }
            }
        },
		Eliminar(selec) {
            if(!selec.ver || (selec.ver && this.previa>1) || (this.formData.exoneracion && this.previa>1) || (this.formData.formapago.code==6 && this.inscripcion.length>1)){
                this.inscripcion = this.inscripcion.filter(val => val !== selec);
                this.tuc = this.tuc - selec.uc;
                this.asignatura = null;
                this.seccion = null;
                Consulta.Procesar('DEL',{
                    id: this.$store.state.matricula,
                    oferta: selec.id,
                }).then(response => {
                    //this.$store.state.error = response.result;
                    if(response.result)	{
                        this.tuc = 0;
                        this.Mostrar();
                    }
                    this.$store.commit('Loading');
                });
            }
		},
		Horario() {
            this.horario=[];
			Consulta.Procesar('Horario',{
				id: this.$store.state.matricula,
				inscripcion: this.inscripcion,
			}).then(response => {
				//this.$store.state.error = response.result;
				this.horario = response.result.horario;
				this.cruce = response.result.cruce;
                this.nextPage();
                this.$store.commit('Loading');
			});
		},
        nextPage() {
            this.submitted = true;
            if (this.validateForm()) {
                this.$emit('next-page', {formData: {facturaprev: this.facturaprev,tuc: this.tuc, inscripcion: this.inscripcion, horario: this.horario, cruce: this.cruce}, pageIndex: 1});
            }
        },
        prevPage() {
            this.$emit('prev-page', {pageIndex: 1});
        },
        validateForm() {
            if (this.inscripcion.length==0)
                this.validationErrors['asignatura'] = true;
            else
                delete this.validationErrors['asignatura'];

            return !Object.keys(this.validationErrors).length;
        }
    }
}
</script>